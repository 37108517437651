import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import Digital from "../../assets/images/services/digital.jpeg"
import { Container } from "react-bootstrap";

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Digital Marketing Outsourcing Company in India"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container>
                        <h6 className="mt-5">Designing experiences that Engage, Convert, and deliver Results.</h6>
                        <p className="mt-2">Users today consume frictionless, instant experiences from digital brands in their daily lives. That expectation does not change when they engage with your brand as customers, partners, or employees.</p>
                        <p>Our vision is to be your partner of choice in our chosen verticals in driving greater revenue, and building greater brand recognition & loyalty by</p>
                        <ul className="circle-list">
                            <li className="mt-2">Providing personalized experiences to customers, employees, and partners across all channels</li>
                            <li className="mt-2">Digitizing the core (or digitize around the core)</li>
                        </ul>
                        <p className="mt-3">We do this by understanding user journeys in specific contexts, investing in accelerators and frameworks that incorporate best practices while reducing the time-to-market. We build this in partnership with key partners (Sitecore, Adobe, OpenText Documentum, Veeva, Newgen).</p>

                        <h6>Our differentiated approach</h6>

                        <ul className="circle-list mt-3">
                            <li className="mt-2">We take a user journey-centric approach to designing and delivering a personalized and consistent experience. The TEQT DX approach recognizes that experiences are enabled by an integrated digital ecosystem cutting across capabilities in marketing, front-end, business processes, organizations core systems, and partner systems.</li>
                            <li className="mt-2">We design for outcomes. We have maturity frameworks and assessment toolkits tailored to outcomes specific to industries and helps anchor DX initiatives in concrete KPIs providing a clear sense of business value that helps define success. In some instances, we have taken on outcome-based models as part of our engagements.</li>
                            <li className="mt-2">We help our clients stand out in the industry they operate in. Our industry knowledge in our chosen verticals provides us differentiated expertise in core systems that power the content for the experience in digital channels as well as opportunities and challenges in those industries.</li>
                            <li className="mt-2">We enable Digitization around the Core to help fulfil the promises of a journey-centric experience by integrating into the business process and connecting to core systems.</li>
                        </ul>
                    </Container>

                </div>





                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query InteractiveServicesPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
